import React, { useState, useEffect } from "react"; // Import React and useState
import { useNavigate, useParams } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import Frontid from "../assets/images/front-id.svg";
import Backid from "../assets/images/back-id.svg";
import Selfieid from "../assets/images/selfie-id.png";
import checkIcon from "../assets/images/check-circle.svg";
// import checkIcon from "../assets/images/done.svg";
import uncheckIcon from "../assets/images/undone.svg";
import deleteImage from "../assets/images/delete.svg";
import { useSelector, useDispatch } from "react-redux";
import { onboardingActions } from "./../store/onboarding.slice";
import { paymentActions } from "./../store/payment.slice";
import HttpStatus from "./../helpers/status.enum";
import SpinnerLoader from "../components/SpinnerLoader";
import { commonActions } from "../store/common.slice";
import { checkInActions } from "../store/checkin.slice";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { Pagination, Navigation } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { toast } from "react-toastify";

// Modalbox

import Modal from "react-bootstrap/Modal";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "../components/CheckoutForm";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function Checkingin({
  checkedInPageHandle,
  checkingPageHandle,
  reservationPageHandle,
}) {
  // Modalbox

  const dispatch = useDispatch();
  const [showCreditCardModal, setShowCreditCardModal] = useState(false);
  const [onboardingStep, setOnboardingStep] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedPaymentOption, setSelectedPaymentOption] =
    useState("nonrefundable");
  const [clientSecret, setClientSecret] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [stripeId, steStripeId] = useState(null);
  const [paymentSlug, setPaymentSlug] = useState(null);
  const [intentType, setintentType] = useState(0);
  const [loader, setLoader] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [cmsContent, setCmsContent] = useState(null);
  const [listingCms, setListingCms] = useState(null);
  const [houseRules, setHouseRules] = useState([]);
  const [houseRuleCount, setHouseRuleCount] = useState(null);
  const [securityDepositAlert, setSecurityDepositAlert] = useState([]);
  const [swiperVerifyRef, setVerifySwiperRef] = useState(null);
  const { listingSlug } = useParams();
  const [isSignedButtonDisabled, setIsSignedButtonDisabled] = useState(true);

  const [showConsentModal, setShowConsentModal] = useState(false);
  // Getting confirmation Code from redux
  const bookingSlug = useSelector((data) => {
    return data.checkIn.bookingSlug;
  });
  const [onBoardingApiFail, setOnBoardingApiFail] = useState(false)

  useEffect(() => {
    const fetchBookingData = async () => {
      try {
        const bookingResult = await dispatch(
          checkInActions.getBookingByCode(bookingSlug)
        );
        if (bookingResult?.payload?.status === HttpStatus.HTTP_OK) {
          if (bookingResult?.payload?.result?.is_guest_form_bypass === 1) {
            reservationPageHandle(true);
            checkingPageHandle(false);
            checkedInPageHandle(false);
            // navigate("/reservationinfo");
          }
          setSecurityDepositAlert(
            bookingResult?.payload?.result?.security_deposit
          );
          setListingCms(bookingResult?.payload?.result?.listing);
          const houseRulesJson =
            bookingResult?.payload?.result?.listing?.house_rules;
          var houseRules = JSON.parse(houseRulesJson);
          houseRules = houseRules.map(function (rule) {
            return {
              id: rule.id,
              label: rule.label,
            };
          });
          setHouseRules(houseRules);
          setHouseRuleCount(
            bookingResult?.payload?.result?.listing?.total_house_rules
          );
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchBookingData();
  }, []);

  const handleCreditCardModalOpen = async () => {
    setLoader(true);
    let intentType = 0;
    if (selectedPaymentOption === "nonrefundable") {
      intentType = 1;
    } else if (selectedPaymentOption === "refundable") {
      intentType = 2;
    }
    setintentType(intentType);
    const formData = {
      booking_slug: bookingSlug,
      intent_type: intentType,
    };
    const result = await dispatch(paymentActions.createPaymentIntent(formData));
    const responses = result?.payload;
    if (responses?.status === HttpStatus.HTTP_OK) {
      const paymentRes = responses.result;
      setClientSecret(paymentRes?.client_secret);
      setCustomerId(paymentRes?.customer_id);
      steStripeId(paymentRes?.id);
      setPaymentSlug(paymentRes?.payment_slug);
      setShowCreditCardModal(true);
      setLoader(false);
    } else {
      toast(responses?.message);
      setLoader(false);
    }
  };

  const options = {
    clientSecret: clientSecret,
    appearance: {
      /*...*/
    },
  };

  // Function to handle radio button payment option change
  const handlePaymentOptionChange = (event) => {
    setSelectedPaymentOption(event.target.value);
  };

  // Callback function to receive data from child component
  const closeCheckoutModal = (data) => {
    setShowCreditCardModal(false);
    setLoader(false);
  };

  // Callback function to receive data from child component
  const gotoHomePage = (data) => {
    setButtonClicked(true);
    setPageLoaderModal(true);
  };

  const getOnboardingStep = async () => {
    try {
      const result = await dispatch(
        onboardingActions.getOnboardingStep(bookingSlug)
      );
      setOnboardingStep(result?.payload);
      setIsLoading(false);

      const cmsResult = await dispatch(commonActions.getCmsContents());
      if (cmsResult?.payload?.status === HttpStatus.HTTP_OK) {
        setCmsContent(cmsResult?.payload?.result);
      }
    } catch (error) {
      console.error("Error fetching data :", error);
    }
  };

  useEffect(() => {
    getOnboardingStep();
    if (onboardingStep?.isCheckinCompleted === true) {
      reservationPageHandle(true);
      checkingPageHandle(false);
      checkedInPageHandle(false);
      // navigate("/reservationinfo");
    }

    if (onboardingStep?.isHouseRulesAccepted === 1) {
      setIsFormSubmittedhouserules(true);
    }
    if (onboardingStep?.signedOnDottedLine === 1) {
      setIsFormSubmittedagree(true);
    }
    if (onboardingStep?.isVerificationIdsUploaded === 1) {
      setIsFormSubmittedverify(true);
    }
  }, [isLoading]);

  // type name and enable button

  const [name, setName] = useState("");
  const [isNameValid, setIsNameValid] = useState(false);
  const [activeAccordionItem, setActiveAccordionItem] = useState(null); // State to control active accordion item

  // const [selectedDocs, setSelectedDocs] = useState([]);
  const [responses, setResponses] = useState([]);

  const handleNameChange = (event) => {
    const inputValue = event.target.value;
    setName(inputValue);
    setIsNameValid(inputValue.trim().length > 0);
    setIsSignedButtonDisabled(!isCheckboxCheckedSign || !inputValue.trim());
  };

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  // };

  // Swiper slider
  const [swiperRef, setSwiperRef] = useState(null);

  // Scrooll Top start
  // Function to scroll to the top of the page

  // const scrollToTop = () => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: "smooth",
  //   });
  // };

  // Scroll to top when component mounts or navigates
  useEffect(() => {
    // Find the position of the "Checking In" section relative to the top of the document
    const checkingInSection = document.getElementById("checkingIn");
    const sectionPosition = checkingInSection.getBoundingClientRect().top;

    // Scroll the page to the position of the "Checking In" section
    window.scrollTo({
      top: sectionPosition,
      behavior: "smooth",
    });
  }, []);

  // Onloader

  const [pageLoaderModal, setPageLoaderModal] = useState(false);
  const navigate = useNavigate();
  const [buttonClicked, setButtonClicked] = useState(false);

  useEffect(() => {
    if (buttonClicked) {
      const timer = setTimeout(() => {
        setPageLoaderModal(false);
        reservationPageHandle(true);
        checkingPageHandle(false);
        checkedInPageHandle(false);
        // navigate("/reservationinfo");
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [buttonClicked, navigate]);

  // Select checkbox

  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [buttonText, setButtonText] = useState("I Agree"); // State to manage button text
  const [verifybuttonText, setverifyButtonText] = useState(
    <div>
      Add photo of front of <span className="text-uppercase">ID</span>
    </div>
  ); // State to manage button text
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false); // State to manage checkbox checked state
  const [isCheckboxCheckedSign, setIsCheckboxCheckedSign] = useState(false); // State to manage checkbox checked state for esign 
  const [checkedCount, setCheckedCount] = useState(0); // State to track the number of checked checkboxes

  // Read the house rules card

  /*const checkboxes = [
    {
      id: "nosmoking",
      label: "There is no smoking inside the premises."
    },
    {
      id: "noparties",
      label: "There are no parties or events at the premises."
    },
    {
      id: "nooutsideguests",
      // label: "No outside guests (only guests on reservation)",
      label: "Respect neighbors. Quiet Hours are 10pm - 7am.",
    }
  ];*/
  const checkboxes = houseRules;
  const handleAgreeButtonClick = () => {
    if(onBoardingApiFail) {
      const formData = {
        is_house_rules_accepted: 1,
        is_signed_on_dotted_line: 0,
        is_verification_ids_uploaded: 0,
        is_deposit_option: 0,
      };
      submitOnboardingSteps(formData);
      return ;
    }
    const checkboxId = checkboxes[currentIndex].id;
    // Toggle the selection of the current checkbox
    setSelectedCheckboxes((prevSelectedCheckboxes) => {
      if (prevSelectedCheckboxes.includes(checkboxId)) {
        return prevSelectedCheckboxes.filter((id) => id !== checkboxId); // Deselect the checkbox
      } else {
        return [...prevSelectedCheckboxes, checkboxId]; // Select the checkbox
      }
    });

    swiperRef.slideNext(); // Move to the next slide
    setCurrentIndex((prevIndex) => (prevIndex + 1) % checkboxes.length); // Wrap around the index
    setIsCheckboxChecked((prevState) => !prevState);

    if (selectedCheckboxes.length === houseRuleCount) {
      const formData = {
        is_house_rules_accepted: 1,
        is_signed_on_dotted_line: 0,
        is_verification_ids_uploaded: 0,
        is_deposit_option: 0,
      };
      submitOnboardingSteps(formData);
    }
    // Increment checkedCount when checkbox is checked
    if (!selectedCheckboxes.includes(checkboxId)) {
      setCheckedCount((prevCount) => prevCount + 1);
    }

    // Update button text dynamically based on checkbox status
    const buttonText = selectedCheckboxes.includes(checkboxId)
      ? "I Agreed"
      : "I Agree";
    setButtonText(buttonText);

    if (selectedCheckboxes.length >= houseRuleCount) {
      setButtonText("I Agreed");
      setIsButtonDisabled(true);
      // setIsFormSubmittedhouserules(true);
    } else {
      setButtonText("I Agree");
      setIsButtonDisabled(false);
      // setIsFormSubmittedhouserules(false);
    }
  };

  const [isFormSubmittedhouserules, setIsFormSubmittedhouserules] =
    useState(false);

  const handleSubmithouserules = (event) => {
    event.preventDefault();
    // Here you can perform any submission logic
    //setIsFormSubmittedhouserules(true); // Set the form submission state to true
  };

  // Function to handle swiper slide change
  const handleSlideChange = () => {
    let checkboxId;
    if (currentIndex > 0) {
      checkboxId = checkboxes[currentIndex - 1].id;
    } else {
      checkboxId = checkboxes[currentIndex].id;
    }

    const buttonText = selectedCheckboxes.includes(checkboxId)
      ? "I agreed"
      : "I agree";
    setButtonText(buttonText);
    setIsButtonDisabled(true);
  };

  // Function to handle swiper slide next
  const handleSlideNext = () => {
    const checkboxId = checkboxes[currentIndex].id;
    const buttonText = selectedCheckboxes.includes(checkboxId)
      ? "I agreed"
      : "I agree";
    setButtonText(buttonText);
    // setIsButtonDisabled(true);
  };

  // Function to handle swiper slide prev
  const handleSlidePrev = () => {
    const checkboxId = checkboxes[currentIndex].id;
    const buttonText = selectedCheckboxes.includes(checkboxId)
      ? "I agreed"
      : "I agree";
    setButtonText(buttonText);
    setIsButtonDisabled(true);
  };

  // Sign on the dotted line

  const [showAgreementModalagree, setShowAgreementModalboxagree] =
    useState(false);

  const [isFormSubmittedagree, setIsFormSubmittedagree] = useState(false);
  const handleAgreementModalOpen = () => {
    setShowAgreementModalboxagree(true);
  };

  const handleSubmitagree = (event) => {
    event.preventDefault();
    // Here you can perform any submission logic
    // setIsFormSubmittedagree(true); // Set the form submission state to true
    setActiveAccordionItem("2"); // Open the third accordion item after signing

    if (name !== "") {
      const formData = {
        is_house_rules_accepted: 0,
        is_signed_on_dotted_line: 1,
        is_verification_ids_uploaded: 0,
        is_deposit_option: 0,

        signed_name: name,
      };
      submitOnboardingSteps(formData);
    }
  };

  // Verifyed it's you upload ID

  const [selectedFrontImage, setSelectedFrontImage] = useState(null);
  const [selectedBackImage, setSelectedBackImage] = useState(null);

  const [showIdCardModal_Front, setshowIdCardModal_Front] = useState(false);
  const [showIdCardModal_Back, setshowIdCardModal_Back] = useState(false);
  const [showIdCardModal_Selfie, setshowIdCardModal_Selfie] = useState(false);

  const [selectedSelfieImage, setSelectedSelfieImage] = useState(null);

  const handleDeleteFrontImage = () => {
    setSelectedFrontImage(null); // Set the front image to null to delete it
  };
  const handleDeleteBackImage = () => {
    setSelectedBackImage(null); // Set the front image to null to delete it
  };
  const handleDeleteSelfieImage = () => {
    setSelectedSelfieImage(null); // Set the front image to null to delete it
  };

  const handleFrontButtonClick = () => {
    document.getElementById("front_id_card").click();
  };

  const handleBackButtonClick = () => {
    document.getElementById("back_id_card").click();
  };

  const handleSelfieButtonClick = (camera = true) => {
    if (camera) {
      document.getElementById("selfie_id").click();
    } else {
      document.getElementById("selfie_id_card").click();
    }
  };

  const handleFrontImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        compressImage(reader.result, (compressedImage) => {
          setSelectedFrontImage(compressedImage);
          if (compressedImage) {
            uploadOnboardingMedia(compressedImage, "front_image");
          }
          /*if (swiperVerifyRef) {
            swiperVerifyRef.slideNext(); // Move to the next slide
          }*/
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const compressImage = (imageData, callback) => {
    const img = new Image();
    img.src = imageData;
    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      // Set the canvas dimensions to the resized image dimensions
      const maxWidth = 800; // Adjust as needed
      const maxHeight = 600; // Adjust as needed
      let width = img.width;
      let height = img.height;

      if (width > maxWidth || height > maxHeight) {
        const aspectRatio = width / height;
        if (width > height) {
          width = maxWidth;
          height = width / aspectRatio;
        } else {
          height = maxHeight;
          width = height * aspectRatio;
        }
      }

      canvas.width = width;
      canvas.height = height;

      // Draw image onto canvas
      ctx.drawImage(img, 0, 0, width, height);

      // Get the compressed image data
      const compressedImageData = canvas.toDataURL("image/jpeg", 1); // Adjust quality (0.7 is just an example)

      callback(compressedImageData);
    };
  };

  const handleBackImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        compressImage(reader.result, (compressedImage) => {
          setSelectedBackImage(compressedImage);
          if (compressedImage) {
            uploadOnboardingMedia(compressedImage, "back_image");
          }
          /*if (swiperVerifyRef) {
            swiperVerifyRef.slideNext(); // Move to the next slide
          }*/
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSelfieImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        compressImage(reader.result, (compressedImage) => {
          setSelectedSelfieImage(compressedImage);
          if (compressedImage) {
            uploadOnboardingMedia(compressedImage, "selfie_image");
          }
          /*if (swiperVerifyRef) {
            swiperVerifyRef.slideNext(); // Move to the next slide
          }*/
        });
      };
      reader.readAsDataURL(file);
    }
    setActiveAccordionItem("2"); // Open the third accordion item after signing
  };

  useEffect(() => {
    if (responses.length === 3) {
      const formData = {
        is_house_rules_accepted: 0,
        is_signed_on_dotted_line: 0,
        is_verification_ids_uploaded: 1,
        is_deposit_option: 0,
        verification_images: responses,
      };
      submitOnboardingSteps(formData);
      // setIsFormSubmittedverify(true); // Set the form submission state to true
    }
  }, [responses]);

  const uploadOnboardingMedia = async (imageBase64Data, eventType) => {
    setIsDisabled(true);
    // localStorage.getItem("listingSlug")
    const formData = {
      file: imageBase64Data,
      listing_slug: listingSlug,
    };
    try {
      const result = await dispatch(onboardingActions.uploadMedia(formData));
      if (result?.payload?.status === HttpStatus.HTTP_OK) {
        const response = result?.payload?.result;
        // Check if an entry with the same eventType exists
        const existingIndex = responses.findIndex(
          (entry) => Object.keys(entry)[0] === eventType
        );

        if (existingIndex !== -1) {
          // Update the existing entry
          setResponses((prevResponses) => {
            const updatedResponses = [...prevResponses];
            updatedResponses[existingIndex] = {
              [eventType]: response.fileName,
            };
            return updatedResponses;
          });
        } else {
          // Add new entry to responses
          setResponses((prevResponses) => [
            ...prevResponses,
            { [eventType]: response.fileName },
          ]);
        }
        if (swiperVerifyRef) {
          swiperVerifyRef.slideNext(); // Move to the next slide
        }
      } else {
        if (eventType === "front_image") {
          setSelectedFrontImage(null);
        }
        if (eventType === "back_image") {
          setSelectedBackImage(null);
        }
        if (eventType === "selfie_image") {
          setSelectedSelfieImage(null);
        }
      }
    } catch (error) {
      console.error("Error occurred while submitting form:", error);
    } finally {
      if(eventType !== "selfie_image"){
        setIsDisabled(false);
      }
    }
  };

  const handleIdCardModalOpen = () => {
    if (selectedFrontImage && selectedBackImage && selectedSelfieImage) {
      if(onBoardingApiFail){
        const formData = {
          is_house_rules_accepted: 0,
          is_signed_on_dotted_line: 0,
          is_verification_ids_uploaded: 1,
          is_deposit_option: 0,
          verification_images: responses,
        };
        submitOnboardingSteps(formData);
      }
      // setIsFormSubmittedverify(true);
    } else if (selectedFrontImage == null) {
      setshowIdCardModal_Front(true);
      setverifyButtonText(
        <div>
          Add photo of front of <span className="text-uppercase">ID</span>
        </div>
      );
    } else if (selectedBackImage == null) {
      setshowIdCardModal_Back(true);
      setverifyButtonText(
        <div>
          Add photo of back of <span className="text-uppercase">ID</span>
        </div>
      );
    } else if (selectedSelfieImage == null) {
      setshowIdCardModal_Selfie(true);
      setverifyButtonText(
        <div>
          Add selfie holding <span className="text-uppercase">ID</span>
        </div>
      );
    }
  };

  const [isFormSubmittedverify, setIsFormSubmittedverify] = useState(false);

  const handleSubmitverify = (event) => {
    event.preventDefault();
    // Here you can perform any submission logic
    // setIsFormSubmittedverify(true); // Set the form submission state to true
    setActiveAccordionItem("2"); // Open the third accordion item after signing
  };

  const handleVerifySlideChange = () => {
    // Determine which slide is active and set button text accordingly
    switch (swiperVerifyRef.activeIndex) {
      case 0:
        setverifyButtonText(
          <div>
            Add photo of front of <span className="text-uppercase">ID</span>
          </div>
        );
        break;
      case 1:
        setverifyButtonText(
          <div>
            Add photo of back of <span className="text-uppercase">ID</span>
          </div>
        );
        break;
      case 2:
        setverifyButtonText(
          <div>
            Add selfie holding <span className="text-uppercase">ID</span>
          </div>
        );
        break;
      default:
        setverifyButtonText(""); // Reset button text if none of the cases match
    }
  };

  // Accrodian
  const [activeAccordionKey, setActiveAccordionKey] = useState("0");

  useEffect(() => {
    // If isFormSubmittedhouserules is true, set the activeAccordionKey to "1"
    if (isFormSubmittedhouserules) {
      setActiveAccordionKey("1");
    }
  }, [isFormSubmittedhouserules]);

  useEffect(() => {
    // If isFormSubmittedhouserules is true, set the activeAccordionKey to "1"
    if (isFormSubmittedagree) {
      setActiveAccordionKey("2");
    }
  }, [isFormSubmittedagree]);

  useEffect(() => {
    // If isFormSubmittedhouserules is true, set the activeAccordionKey to "1"
    if (isFormSubmittedverify) {
      setActiveAccordionKey("3");
    }
  }, [isFormSubmittedverify]);

  // Body in add class for overlay remove

  const handleClick = () => {
    // Add your class to the body tag
    document.body.classList.add("signOndotted");
  };

  const submitOnboardingSteps = async (requestData) => {
    const formData = {
      booking_slug: bookingSlug,
      ...requestData,
    };
    try {
      const result = await dispatch(
        onboardingActions.submitOnboardingSteps(formData)
      );
      if (result?.payload?.status === HttpStatus.HTTP_OK) {
        setOnBoardingApiFail(false);
        checkingPageHandle(true);
        checkedInPageHandle(false);
        if (requestData.is_house_rules_accepted === 1) {
          setIsFormSubmittedhouserules(true);
        }
        if (requestData.is_signed_on_dotted_line === 1) {
          setIsFormSubmittedagree(true);
        }
        if (requestData.is_verification_ids_uploaded === 1) {
          setIsFormSubmittedverify(true);
          setIsDisabled(false);
        }
        // navigate("/checkingin");
      } else {
        // Handle other cases if needed
        setOnBoardingApiFail(true);
        if (requestData.is_house_rules_accepted === 1) {
          setIsButtonDisabled(false);
        }
        if (requestData.is_verification_ids_uploaded === 1) {
          setverifyButtonText(<div>Proceed</div>);
          setIsDisabled(false);
        }
      }
    } catch (error) {
      console.error("Error occurred while submitting form:", error);
    }
  };

  const handleClosePaymentModal = () => {
    setShowCreditCardModal(false);
    setLoader(false);
  };

  const handleCheckboxChange = (event) => {
    const { checked } = event.target;
    setIsCheckboxCheckedSign(checked);
    setIsSignedButtonDisabled(!checked || !name.trim());
  };

  useEffect(() => {
    const container = document.querySelector(".checkBox-text-heading");
    const handleClick = (event) => {
      if (event.target && event.target.matches(".checkBox-text-heading-link")) {
        setShowConsentModal(true);
      }
    };
    if (container) {
      container.addEventListener("click", handleClick);
    }
    return () => {
      if (container) {
        container.removeEventListener("click", handleClick);
      }
    };
  }, [showAgreementModalagree]);

  return (
    <section className="checkinginpage">
      <h3 id="checkingIn">Checking In</h3>
      <div className="card-section">
        <div className="accrodianBlock">
          {/* <Accordion activeKey={activeAccordionKey} flush> */}
          <Accordion activeKey={activeAccordionKey} flush>
            <Accordion.Item
              eventKey={!isFormSubmittedhouserules ? "0" : "fixedKey"} // Use a fixed key when isFormSubmittedhouserules is true
              disabled={isFormSubmittedhouserules}
              className={`cardYellow ${
                isFormSubmittedhouserules
                  ? "accrodionCollapsed"
                  : "accrodionCollapse"
              }`}
            >
              <Accordion.Header>
                {isFormSubmittedhouserules && <img src={checkIcon} />}{" "}
                <h2
                  style={
                    isFormSubmittedhouserules
                      ? { textDecoration: "line-through" }
                      : {}
                  }
                >
                  {" "}
                  {isFormSubmittedhouserules ? (
                    " "
                  ) : (
                    <img src={uncheckIcon} />
                  )}{" "}
                  <span>Read the House Rules</span>
                </h2>
              </Accordion.Header>
              <Accordion.Body>
                <hr className="mt-0 mb-3" />
                <h5>Please agree to all before continuing</h5>
                <Form onSubmit={handleSubmithouserules} className="mt-1">
                  <div className="swiperSliderone">
                    <Swiper
                      id="rulesswiper"
                      onSwiper={(swiper) => setSwiperRef(swiper)} // Set swiperRef when Swiper is initialized
                      spaceBetween={10}
                      slidesPerView={1.5}
                      pagination={{ type: "fraction" }}
                      navigation={false}
                      modules={[Pagination, Navigation]}
                      className="mySwiper"
                      // onSlideChange={handleSlideChange} // Handle slide change event
                      onSlideNext={handleSlideNext} // Handle slide next event
                      onSlidePrev={handleSlidePrev} // Handle slide prev event
                      allowSlidePrev={isCheckboxChecked}
                      allowSlideNext={true} // Enable sliding to the next slide only if a checkbox is checked
                      allowTouchMove={isCheckboxChecked} // Allow touch interactions only if a checkbox is checked
                    >
                      {checkboxes.map((checkbox) => (
                        <SwiperSlide
                          key={checkbox.id}
                          style={{ width: "250px" }}
                          onClick={() => {
                            handleAgreeButtonClick();
                          }}
                        >
                          <div>
                            {/* <Form.Group className="mb-3" controlId={key}> */}
                            <Form.Check
                              type="checkbox"
                              id={checkbox.id}
                              checked={selectedCheckboxes.includes(checkbox.id)}
                              label={checkbox.label}
                              disabled
                              onChange={() => {
                                const isChecked = selectedCheckboxes.includes(
                                  checkbox.id
                                );
                                if (isChecked) {
                                  // If checkbox is already checked, remove it from the selectedCheckboxes array
                                  setSelectedCheckboxes(
                                    selectedCheckboxes.filter(
                                      (id) => id !== checkbox.id
                                    )
                                  );
                                } else {
                                  // If checkbox is unchecked, add it to the selectedCheckboxes array
                                  setSelectedCheckboxes([
                                    ...selectedCheckboxes,
                                    checkbox.id,
                                  ]);
                                }
                              }}
                            />
                            {/* </Form.Group> */}
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                  <hr className="mt-3 mb-4" />
                  <div className="d-flex justify-content-center">
                    <Button
                      className="fillbutton"
                      type="submit"
                      disabled={isButtonDisabled}
                      onClick={handleAgreeButtonClick}
                    >
                      {buttonText}
                    </Button>
                  </div>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              eventKey={!isFormSubmittedagree ? "1" : "fixedKey"} // Use a fixed key when isFormSubmittedagree is true
              disabled={isFormSubmittedagree}
              className={`cardOrange ${
                isFormSubmittedagree
                  ? "accrodionCollapsed"
                  : "accrodionCollapse"
              }`}
            >
              <Accordion.Header>
                {isFormSubmittedagree && <img src={checkIcon} />}{" "}
                <h2
                  style={
                    isFormSubmittedagree
                      ? { textDecoration: "line-through" }
                      : {}
                  }
                >
                  {" "}
                  {isFormSubmittedagree ? " " : <img src={uncheckIcon} />}{" "}
                  <span>Sign on the dotted line</span>
                </h2>
              </Accordion.Header>
              <Accordion.Body>
                <hr className="mt-0 mb-3" />
                <Form className="mt-1">
                  <div className="readagreement">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: listingCms?.rental_instructions,
                      }}
                    />
                    {/* <p className="text-upppercase"> RENTAL AGREEMENT </p>
                    <p className="text-upppercase">
                      {" "}
                      1: RESERVATION INFORMATION{" "}
                    </p>
                    <p className="text-upppercase"> CHECK-IN </p>
                    <p>
                      The check-in time is after 3:00 p.m. on the first day of
                      your reservation. Sometimes early check-in is available
                      and is easy to purchase on your Happy Guest reservation
                      page.
                    </p>
                    <p className="text-upppercase"> CHECK-OUT </p>
                    <p>
                      The check-out time is before 11:00 a.m. on the last day of
                      your reservation. Sometimes late check-out is available
                      and is easy to purchase on your Happy Guest reservation
                      page. Late fees will be charged for departures after 11:00
                      a.m. that did not purchase a late check-out.
                    </p> */}
                  </div>
                  <div className="d-flex justify-content-center">
                    <Button
                      className="fillbutton"
                      onClick={handleAgreementModalOpen}
                    >
                      I have read the agreement
                    </Button>
                  </div>
                </Form>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey={!isFormSubmittedverify ? "2" : "fixedKey"} // Use a fixed key when isFormSubmittedverify is true
              disabled={isFormSubmittedverify}
              className={`cardBlue ${
                isFormSubmittedverify
                  ? "accrodionCollapsed"
                  : "accrodionCollapse"
              }`}
            >
              <Accordion.Header>
                {isFormSubmittedverify && <img src={checkIcon} />}{" "}
                <h2
                  style={
                    isFormSubmittedverify
                      ? { textDecoration: "line-through" }
                      : {}
                  }
                >
                  {" "}
                  {isFormSubmittedverify ? " " : <img src={uncheckIcon} />}{" "}
                  <span>Verify it's you</span>
                </h2>
              </Accordion.Header>
              <Accordion.Body>
                <hr className="mt-0 mb-3" />
                <h5>Please add photos of your ID</h5>
                <span className="sub-title mb-2">
                  Can be a Passport or Driver's License
                </span>
                <Form className="mt-1" onSubmit={handleSubmitverify}>
                  <div className="swiperSliderone">
                    <Swiper
                      id="photoswiper"
                      onSwiper={(swiper) => setVerifySwiperRef(swiper)} // Set swiperRef when Swiper is initialized
                      spaceBetween={10}
                      slidesPerView={1.5} // Set slidesPerView to 1.5
                      //onSlideChange={() => console.log("slide change")}
                      onSlideChange={handleVerifySlideChange}
                      allowSlidePrev={
                        selectedFrontImage &&
                        selectedBackImage &&
                        selectedSelfieImage
                          ? true
                          : false
                      } // Allow sliding to previous slide if any image is uploaded
                      allowSlideNext={true} // Enable sliding to the next slide only if all images are uploaded
                      allowTouchMove={
                        selectedFrontImage &&
                        selectedBackImage &&
                        selectedSelfieImage
                          ? true
                          : false
                      } // Allow touch interactions only if all images are uploaded
                      pagination={{
                        type: "fraction",
                      }}
                      navigation={false}
                      modules={[Pagination, Navigation]}
                      className="mySwiper"
                    >
                      <SwiperSlide>
                        <div
                          className="id_card_block"
                          onClick={handleIdCardModalOpen}
                        >
                          {selectedFrontImage ? (
                            <div className="id_card_uploaded">
                              <figure>
                                <img src={selectedFrontImage} alt="Preview" />
                                <span
                                  className="deletePreview"
                                  onClick={handleDeleteFrontImage}
                                >
                                  <img src={deleteImage} />
                                </span>
                              </figure>
                            </div>
                          ) : (
                            <div className="id_card_uploaded">
                              <div>
                                <img src={Frontid} alt="Preview" />
                                <p className="id_label">
                                  Front of <span>ID</span>
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div
                          className="id_card_block"
                          onClick={handleIdCardModalOpen}
                        >
                          {selectedBackImage ? (
                            <div className="id_card_uploaded">
                              <figure>
                                <img src={selectedBackImage} alt="Preview" />
                                <span
                                  className="deletePreview"
                                  onClick={handleDeleteBackImage}
                                >
                                  <img src={deleteImage} />
                                </span>
                              </figure>
                            </div>
                          ) : (
                            <div className="id_card">
                              <div>
                                <img src={Backid} alt="Preview" />
                                <p className="id_label">
                                  Back of <span>ID</span>
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div
                          className="id_card_block"
                          onClick={handleIdCardModalOpen}
                        >
                          {selectedSelfieImage ? (
                            <div className="id_card_uploaded_selfie">
                              <figure>
                                <img src={selectedSelfieImage} alt="Preview" />
                                <span
                                  className="deletePreview"
                                  onClick={handleDeleteSelfieImage}
                                >
                                  <img src={deleteImage} />
                                </span>
                              </figure>
                            </div>
                          ) : (
                            <div className="id_card_selfie">
                              <div>
                                <img src={Selfieid} alt="Preview" />
                                <p className="id_label">
                                  Selfie with <span>ID</span>
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      </SwiperSlide>
                    </Swiper>
                  </div>

                  <span
                    className="sub-title-2 mt-3 mb-3"
                    dangerouslySetInnerHTML={{
                      __html: cmsContent?.photo_blury,
                    }}
                  ></span>
                  <div className="d-flex justify-content-center">
                    <Button
                      className="fillbutton"
                      // type="submit"
                      disabled={isDisabled ? true : false}
                      onClick={handleIdCardModalOpen}
                    >
                      {isDisabled ? (
                        <>
                          <SpinnerLoader />
                        </>
                      ) : (
                        verifybuttonText
                      )}
                      {/* {verifybuttonText} */}
                      {/* Add photo of front of{" "}
                      <span className="text-uppercase">ID</span> */}
                    </Button>
                  </div>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3" className="cardGreen">
              <Accordion.Header>
                <h2>
                  <img src={uncheckIcon} /> <span>Swipe for Incidentals</span>
                </h2>
              </Accordion.Header>
              <Accordion.Body>
                <Form className="mt-0">
                  <hr className="mt-0 mb-2" />
                  <div className="radiobox">
                    <Form.Group className="mb-1" controlId="nonrefundable">
                      <Form.Check
                        type="radio"
                        // label={securityDepositAlert?.non_refundable_title}
                        label={
                          <span
                            className="swipe-html-text"
                            dangerouslySetInnerHTML={{
                              __html:
                                securityDepositAlert?.non_refundable_title,
                            }}
                          />
                        }
                        name="paymentOption"
                        value="nonrefundable"
                        // defaultChecked // Add defaultChecked here
                        checked={selectedPaymentOption === "nonrefundable"}
                        onChange={handlePaymentOptionChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-2" controlId="refundable">
                      <Form.Check
                        type="radio"
                        // label={securityDepositAlert?.refundable_title}
                        label={
                          <span
                            className="swipe-html-text"
                            dangerouslySetInnerHTML={{
                              __html: securityDepositAlert?.refundable_title,
                            }}
                          />
                        }
                        name="paymentOption"
                        value="refundable"
                        checked={selectedPaymentOption === "refundable"}
                        onChange={handlePaymentOptionChange}
                      />
                    </Form.Group>
                  </div>
                  <hr className="mt-0 mb-3" />

                  <span
                    dangerouslySetInnerHTML={{
                      __html: cmsContent?.incidental_description,
                    }}
                  />
                  <div className="d-flex justify-content-center">
                    <Button
                      className="fillbutton"
                      onClick={handleCreditCardModalOpen}
                      disabled={loader ? true : false}
                    >
                      {loader ? (
                        <>
                          <SpinnerLoader />
                        </>
                      ) : (
                        "Add Credit Card & Pay"
                      )}
                    </Button>
                  </div>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>

      {/* i have read the agreement */}
      <Modal
        show={showAgreementModalagree}
        onHide={() => setShowAgreementModalboxagree(false)}
        dialogClassName="modal-90w modal-bottom"
        aria-labelledby="example-custom-modal-styling-title"
        className={`agreement ${
          isFormSubmittedagree ? "agreement-d-none" : "agreement-d-block"
        }`}
      >
        <Modal.Header
          closeButton
          onClick={() => setShowAgreementModalboxagree(false)}
        ></Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmitagree}>
            <h2>Please sign the rental agreement</h2>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control
                type="text"
                placeholder="Your name"
                value={name}
                onChange={handleNameChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="agreementCheckbox">
              <div className="d-flex align-items-start">
                <label class="custom-checkbox">
                  <input
                    type="checkbox"
                    checked={isCheckboxCheckedSign}
                    onChange={(e) => handleCheckboxChange(e)}
                  />
                  <span class="checkmark"></span>
                </label>
                <div className="checkBox-text">
                  <span
                    className="checkBox-text-heading"
                    dangerouslySetInnerHTML={{
                      __html: `${cmsContent?.signing_agreement_text} <a href="javascript:void(0)" class="checkBox-text-heading-link" >here</a>`,
                    }}
                  ></span>
                </div>
              </div>
            </Form.Group>
            <div className="d-flex justify-content-between">
              <Button
                className="outlinebutton"
                onClick={() => setShowAgreementModalboxagree(false)}
              >
                Cancel
              </Button>
              <Button
                className="fillbutton"
                type="submit"
                disabled={isSignedButtonDisabled}
                onClick={handleClick}
              >
                Signed!
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {/* End i have read the agreement */}

      {/* Modal for Consumer Consent Disclosure */}
      <Modal 
      className="consumer-content-modal"
      show={showConsentModal} onHide={() => setShowConsentModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Consumer Consent Disclosure</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span
            className="sub-title-2 mt-3 mb-3"
            dangerouslySetInnerHTML={{
              __html: cmsContent?.consumer_content_discloser,
            }}
          ></span>
        </Modal.Body>
      </Modal>
      {/* Add Credit Card & Pay */}
      <Modal
        show={showCreditCardModal}
        onHide={() => handleClosePaymentModal()}
        dialogClassName="modal-90w modal-bottom"
        aria-labelledby="example-custom-modal-styling-title"
        className="addcreditcard"
      >
        <Modal.Header
          closeButton
          onClick={() => handleClosePaymentModal()}
        ></Modal.Header>
        <Modal.Body>
          <Elements stripe={stripePromise} options={options}>
            <CheckoutForm
              closeCheckoutModal={closeCheckoutModal}
              gotoHomePage={gotoHomePage}
              bookingSlug={bookingSlug}
              clientSecret={clientSecret}
              customerId={customerId}
              stripeId={stripeId}
              paymentSlug={paymentSlug}
              intentType={intentType}
              cmsContent={cmsContent}
              securityDepositAlert={securityDepositAlert}
            />
          </Elements>
        </Modal.Body>
      </Modal>
      {/* End i have read the agreement */}

      {/* Upload id card */}
      <Modal
        show={showIdCardModal_Front}
        // onHide={() => setshowFrontIdCardModal(false)}
        onChange={() => setshowIdCardModal_Front(false)}
        dialogClassName="modal-90w modal-bottom"
        aria-labelledby="example-custom-modal-styling-title"
        className="idcard"
      >
        <Modal.Header
          closeButton
          onClick={() => setshowIdCardModal_Front(false)}
        ></Modal.Header>
        <Modal.Body className="pb-4">
          <Form onSubmit={handleSubmitverify}>
            <h2 className="mb-3">How do you want to upload? </h2>
            {/* <hr/> */}

            <div className="d-flex flex-column justify-content-between gap-2">
              <div className="id_screen_upd">
                <Button
                  className="outlinebutton w-100"
                  onClick={handleFrontButtonClick}
                >
                  Take Photo
                </Button>
                <input
                  type="file"
                  name="front_image"
                  accept="image/png, image/jpg, image/jpeg,image/heic"
                  id="selfie_id"
                  capture="camera"
                  className="input_file_upload"
                  onChange={handleFrontImageChange} // Add onChange event handler
                />
              </div>

              <div className="id_screen_upd">
                <Button
                  className="outlinebutton w-100 btn"
                  onClick={handleFrontButtonClick}
                >
                  Select from photo library
                </Button>
                <input
                  type="file"
                  name="front_image"
                  accept="image/png, image/jpg, image/jpeg,image/heic"
                  id="front_id_card"
                  className="input_file_upload"
                  onChange={handleFrontImageChange} // Add onChange event handler
                />
              </div>

              {/* <div className="id_screen_upd">
                <Button className="outlinebutton w-100 btn">Take photo</Button>
                <input
                  type="file"
                  name="front_image"
                  accept="image/png, image/jpg, image/jpeg,image/heic"
                  id="selfie_id"
                  capture="camera"
                  onChange={handleSelfieImageChange} 
                />
              </div> */}
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal
        show={showIdCardModal_Back}
        onChange={() => setshowIdCardModal_Back(false)}
        dialogClassName="modal-90w modal-bottom"
        aria-labelledby="example-custom-modal-styling-title"
        className="idcard"
      >
        <Modal.Header
          closeButton
          onClick={() => setshowIdCardModal_Back(false)}
        ></Modal.Header>
        <Modal.Body className="pb-4">
          <Form onSubmit={handleSubmitverify}>
            <h2 className="mb-3">How do you want to upload? </h2>
            {/* <hr/> */}

            <div className="d-flex flex-column justify-content-between gap-2">
              <div className="id_screen_upd">
                <Button
                  className="outlinebutton w-100"
                  onClick={handleBackButtonClick}
                >
                  Take Photo
                </Button>
                <input
                  type="file"
                  name="front_image"
                  accept="image/png, image/jpg, image/jpeg,image/heic"
                  id="selfie_id"
                  capture="camera"
                  className="input_file_upload"
                  onChange={handleBackImageChange} // Add onChange event handler
                />
              </div>
              <div className="id_screen_upd">
                <Button
                  className="outlinebutton w-100 btn"
                  onClick={handleBackButtonClick}
                >
                  Select from photo library
                </Button>
                <input
                  type="file"
                  name="front_image"
                  accept="image/png, image/jpg, image/jpeg,image/heic"
                  id="back_id_card"
                  className="input_file_upload"
                  onChange={handleBackImageChange} // Add onChange event handler
                />
              </div>

              {/* <div className="id_screen_upd">
                <Button className="outlinebutton w-100 btn">Take photo</Button>
                <input
                  type="file"
                  name="front_image"
                  accept="image/png, image/jpg, image/jpeg,image/heic"
                  id="selfie_id"
                  capture="camera"
                  onChange={handleSelfieImageChange} 
                />
              </div> */}
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal
        show={showIdCardModal_Selfie}
        onChange={() => setshowIdCardModal_Selfie(false)}
        dialogClassName="modal-90w modal-bottom"
        aria-labelledby="example-custom-modal-styling-title"
        className="idcard"
      >
        <Modal.Header
          closeButton
          onClick={() => setshowIdCardModal_Selfie(false)}
        ></Modal.Header>
        <Modal.Body className="pb-4">
          <Form onSubmit={handleSubmitverify}>
            <h2 className="mb-3">How do you want to upload? </h2>
            {/* <hr/> */}

            <div className="d-flex flex-column justify-content-between gap-2">
              <div className="id_screen_upd">
                <Button
                  className="outlinebutton w-100 btn"
                  onClick={handleSelfieButtonClick}
                >
                  Take photo
                </Button>
                <input
                  type="file"
                  name="front_image"
                  accept="image/png, image/jpg, image/jpeg,image/heic"
                  id="selfie_id"
                  capture="camera"
                  className="input_file_upload"
                  onChange={handleSelfieImageChange} // Add onChange event handler
                />
              </div>
              <div className="id_screen_upd">
                <Button
                  className="outlinebutton w-100 btn"
                  onClick={() => handleSelfieButtonClick(false)}
                >
                  Select from photo library
                </Button>
                <input
                  type="file"
                  name="front_image"
                  accept="image/png, image/jpg, image/jpeg,image/heic"
                  id="selfie_id_card"
                  className="input_file_upload"
                  onChange={handleSelfieImageChange} // Add onChange event handler
                />
              </div>

              {/* <div className="id_screen_upd">
                <Button className="outlinebutton w-100 btn">Take photo</Button>
                <input
                  type="file"
                  name="front_image"
                  accept="image/png, image/jpg, image/jpeg,image/heic"
                  id="selfie_id"
                  capture="camera"
                  onChange={handleSelfieImageChange} 
                />
              </div> */}
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {/* End Upload id card */}

      {/* Loader modalbox */}
      <Modal
        show={pageLoaderModal}
        // onHide={() => setshowIdCardModal(false)}
        dialogClassName="modal-90w modal-bottom"
        aria-labelledby="example-custom-modal-styling-title"
        className="pageloaderBox"
      >
        <Modal.Body>
          <div className="loaderContent">
            <h1 className="spinnerText">
              <span className="spintextone d-block mb-3"></span>
              <span className="spintexttwo d-block"></span>
            </h1>
          </div>
        </Modal.Body>

        <div className="welcomebgvideomodal">
          <video autoPlay muted playsInline loop id="video">
            <source
              src={
                process.env.REACT_APP_AWS_CLOUD_FRONT_URL +
                "/Stream_Videos/welcome-bg.mp4"
              }
              type="video/mp4"
            />
            {/* <source src="https://d2rweuuav95dhv.cloudfront.net/Stream_Videos/welcome-bg.mp4" type="video/mp4" /> */}
            {/* <source src="https://d2rweuuav95dhv.cloudfront.net/video/welcome-bg.ogg" type="video/ogg" /> */}
            {/* <source src="https://d2rweuuav95dhv.cloudfront.net/video/welcome-bg-new.webm" type="video/webm" /> */}
          </video>
        </div>
        <div className="welcomebgvideomodalmobile">
          <video autoPlay muted playsInline loop id="video">
            {/* <source src="https://d2rweuuav95dhv.cloudfront.net/Stream_Videos/welcome-bg.mp4" type="video/mp4" /> */}
            {/* <source src="https://d2rweuuav95dhv.cloudfront.net/Stream_Videos/welcome-bg.mp4" type="video/mp4" /> */}
            {/* <source src="https://d2rweuuav95dhv.cloudfront.net/video/welcome-bg.ogg" type="video/ogg" /> */}
            {/* <source src="https://d2rweuuav95dhv.cloudfront.net/Stream_Videos/welcome-bg-1.webm" type="video/webm" /> */}
            <source
              src={
                process.env.REACT_APP_AWS_CLOUD_FRONT_URL +
                "/Stream_Videos/welcome-bg.mp4"
              }
              type="video/mp4"
            />
          </video>
        </div>
      </Modal>
      {/* End Loader modalbox */}
    </section>
  );
}
export default Checkingin;
